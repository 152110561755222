import React, { useContext } from 'react'
//import FooterSimple from '../FooterSimple'
import Navbar from '../xuan2023/navbar'
import BioCardTopPic from '../xuan2023/biocardtopic'
//import SlideComp from './../slideshow/slidecomp'
//import SlideListImages from '../slides/SlideComp' 
import GetLoiSongHangNgay from '../GetLoiSongHangNgay'
import AuthContext from '../store/auth'
import PhatThanhTinLanh from '../PhatThanhTinLanh'
import WpPosts from '../libpost/WpPosts'
import '../xuan2023/modal.css'

import Popup from "reactjs-popup";
//import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "reactjs-popup/dist/index.css";
import CommonFooter from '../xuan2023/common_footer'
//import VideoCarousel from '../videos/CarouselVideos'
import CarouselVideoComp from '../videos/CarouselVideoComp'
import ShowBible from '../bible'
import RandomVerse from '../xuan2023/RandomVerse'

const MyModal = () => {

    return (
        <Popup trigger={<button className="button lead"><span style={{ color: 'darkblue', fontSize: '1.2rem', fontWeight: 'bold' }}> Giờ Nhóm Hằng Tuần </span></button>} modal closeOnDocumentClick >
            {close => (<>

                <div className='d-flex justify-content-end'><a className='close' onClick={close}>[&times;]</a></div>
                <div className="card">
                    <div className="card-header text-primary">
                        <h2>Chương Trình nhóm lại hằng tuần:</h2>
                    </div>
                    <div className="card-body">
                        <h2 className="card-title text-primary">&nbsp;</h2>
                        <div className="d-flex justify-content-center">
                            <ul className='list-group list-group-horizontal text-center'>
                                <li className='list-group-item'><a href='/indexVN'>Thờ phượng chung</a>: 10:30am-12:00pm</li>
                                <li className='list-group-item'><a href='/yem'>Thanh thiếu niên</a>: 10:45am-12:00pm</li>
                                <li className='list-group-item'><a href='/cm'>Nhi đồng</a>: 11:45am-12:45pm</li>
                            </ul>
                        </div>
                        <div className="d-flex justify-content-center">
                            <p>&nbsp;</p>
                            <address className='text-primary lead'>Địa chỉ nhà thờ:<br />
                            Hội Thánh Lời Chúa<br />
                            3134 Frick Rd<br />
                            Houston , TX 77038<br />
                            Tel: (713) 239-1466<br />
                            Quản nhiệm: MS Phạm Văn Huyên<br />
                            Email: quannhiem@loichua.church
                        </address>
                        </div>
                    </div>
                </div>
            </>
            )}

        </Popup>
    )
};




const MotherDay = (props) => {
    //defineaster list of data images here as js object below to use SlideListImages component
    const authCtx = useContext(AuthContext);

    const fontBold = {
        color: 'darkblue', fontSize: '1.2rem', fontWeight: 'bold'
    }
    const kdnVideoList = [
        { //keep adding in the format below 
            id: 1,
            title: "Family Song",//"Dem Thanh Bet Le Hem",
            src: "https://youtu.be/NkZw0oslSOY",//""https://www.youtube.com/watch?v=vUkMeECsoPI",
            credit: ""
        },
        { //keep adding in the format below 
            id: 2,
            title: "", //"Bon Chan Chien",
            src: "https://www.youtube.com/watch?v=WE-5iZQ8t1k",
            credit: ""
        },
        { //keep adding in the format below 
            id: 3,
            title: "", //"Den Voi Vui Mung",
            src: "https://www.youtube.com/watch?v=ZpaYC7Tz3mg",
            credit: ""
        },
        //https://youtu.be/
    ]
    const yemVideoList = [
        { //keep adding in the format below 
            id: 1,
            title: "YEM Anniversary",
            src: "https://www.youtube.com/watch?v=OjQVqPA4kLs",
            credit: "Video by Social Media Team HTLC"
        },
        {
            id: 2,
            title: "",
            src: "https://www.youtube.com/watch?v=ihJvYOTCVZ0",
            credit: "Video by Social Media Team HTLC",
        },
    ]
    const sermonVideoList = [
	    { //keep adding in the format below 
            id: 6,
            title: "Điều Răn Mới",
            src: "https://youtu.be/Bdif6KxJTBc",
            credit: "Video by Social Media Team HTLC"
        },
        { //keep adding in the format below 
            id: 5,
            title: "Yêu Kẻ Lân Cân",
            src: "https://youtu.be/5IZ68OQ4JGM",
            credit: "Video by Social Media Team HTLC"
        },
        { //keep adding in the format below 
            id: 1,
            title: "Nam Moi Cung Nhau Tan Toi",
            src: "https://youtu.be/mvqCXsPEZDs",
            credit: "Video by Social Media Team HTLC"
        },
        { //keep adding in the format below 
            id: 2,
            title: "Trung Tin Den Chet",
            src: "https://www.youtube.com/watch?v=6UrEMcK0B5c",
            credit: "Video by Social Media Team HTLC"
        },
        { //keep adding in the format below 
            id: 7,
            title: "Bua tiec yeu thuong",
            src: "https://www.youtube.com/watch?v=8l75zqLOJTk",
            credit: "Video by Social Media Team HTLC"
        },
        { //keep adding in the format below 
            id: 4,
            title: "Chua Dang Cho",
            src: "https://www.youtube.com/watch?v=vYQqB6XshhM",
            credit: "Video by Social Media Team HTLC"
        },
    ]
    return (
        <>
            <header>
                <div className="overlay">
                    <div className='container-fluid'>
                        <Navbar />

                    </div>
                </div>
               {/*
                <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop" width='100%'>
                    <source src="/assets/media/HappyMotherDayVideo.mp4" type="video/mp4" />
                </video>
                 */}

            </header>
            <div className='container-fluid'>
                <div className='row'>
                  <div className='col'>
                      <img src='/assets/images-cus/WeeklyService3.png' className='img-fluid' width='100%' />
                  </div>
                </div>
                <div className='row'>
                    <div className='col-4'>
                         <a href='/'><img width='550' src='/assets/images-cus/twenty-year-ani.png' className='img-fluid'/></a>
                        <br />
                        <a href='/'><img width='550' src='/assets/images-cus/phatthanhtinlanh.png' className='img-fluid' alt='phat thanh tin lanh - hoi thánh lời Chúa houston texas - nha tho tin lanh houston' /></a>
                        <br />
                        <PhatThanhTinLanh />
                        <br />
                        <span style={{ paddingLeft: '1rem' }}><img width='550' src='/assets/images-cus/daily-bread.jpg' className='img-fluid' alt='loi song hang ngay' />
                        </span>
                        <GetLoiSongHangNgay />
                        <br />
                        {/*
                        <a href='https://thuvien.loichua.church/mua-chay-lent/'><img width='550' src='/assets/images-cus/RememberingHisSacrifice.png' className='img-fluid' alt='tim hieu ve mua chay tai nha tho tin lanh- lent season' /></a>
                        <br />
                        <audio controls>
                            <source src="https://tinlanh.org/Audio/PhucAm/MuaChay.mp3" type='audio/mpeg' />
                        </audio>
                        <br />
                        <a href='https://thuvien.loichua.church/khi-cay-vi-cam-len-tieng/' target='new'><img width='550' src='/assets/images-cus/when-violin-speaks-up.png' className='img-fluid' alt='khi cay vi cam len tieng' /></a>
                        <br />
                        */}
                        <a href='https://thuvien.loichua.church/' target='new'><img width='630' src='/assets/images-cus/church-blog.png' className='img-fluid' alt='thu vien tin lanh - loi chua' /></a>
                        <br />





                    </div>
                    <div className='col-8'>
                          {/*
                          <div className="shadow-lg p-3 mb-5 bg-body rounded" style={{ marginLeft: '10px' }}>
                            <p className='left-align-text lead'><span style={fontBold}>Giờ thờ phượng mỗi Chúa nhật:</span>
                                <br />
                                Trường Chúa Nhật: 9:30AM
                                <br />
                                Thờ phượng chung: 10:30AM
                                <br />
                                <a href='/yem' style={fontBold}>Thanh Thiếu Niên</a>: 10:30AM-12:00PM
                                <br />
                                <a href='/children-ministry' style={fontBold}>Nhi đồng</a>: 11:45AM-12:45PM
                                Mong được đón tiếp quí vị.
                            </p>
                        </div> */}
                           <div className="shadow-lg p-3 mb-5 bg-body rounded" style={{ marginLeft: '10px' }}>
                            <p className='left-align-text lead'><a href='/' style={fontBold}>Sứ Điệp </a></p>
                            <CarouselVideoComp videoArray={sermonVideoList} />
                        </div>
                        
                        <div className="shadow-lg p-3 mb-5 bg-body rounded" style={{ marginLeft: '10px' }}>
                            <p className='left-align-text lead'><a href='/yem' style={fontBold}>Youth English Ministry (YEM) Activities</a></p>
                            {/*
                            <SlideListImages data={dataImg}/>
                            */}
                            <CarouselVideoComp videoArray={yemVideoList} />
                            <br />

                        </div>

                        <div className="shadow-lg p-3 mb-5 bg-body rounded" style={{ marginLeft: '10px' }}>
                            <p className='left-align-text lead'><a href='/' style={fontBold}>Ban Nam Giới & Phụ Nữ</a></p>
                            <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vSSkOXoCCc0Dug5IUKzaTpz5sYwQGemA9lvSZ4kYardaC0etpv17RfdjKwCV6LHYXpHaSRGGRECrAY9/embed?start=true&loop=true&delayms=2000" frameborder="0" width="480" height="299" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" title='HT Loi Chua - sinh hoat nam gioi phu nu' />
                        </div>
                        
                        <div className="shadow-lg p-3 mb-5 bg-body rounded" style={{ marginLeft: '10px' }}>
                             <WpPosts />
                        </div>
                        
                    </div>
                </div>
                <div className='row'>
                    <h2 className='text-primary'>Ban Linh vụ</h2>
                    <div className='col'><BioCardTopPic imgUrl='https://loichua.church/assets/images-cus/ms-huyen.jpeg' name='Rev Phạm Văn Huyên, MDiv' position='Senior Pastor' />

                    </div>
                    <div className='col'><BioCardTopPic imgUrl='https://loichua.church/assets/images-cus/pastor-ngon-bui.jpg' name='Rev Bùi Trung Ngôn, BA' position='Assistant Pastor' />
                    </div>
                    <div className='col'><BioCardTopPic imgUrl='https://loichua.church/assets/images-cus/ms-le-thuan-3.jpg' name='Rev Lê Phước Thuận, MDiv' position='Pastor' />

                    </div>
                    <div className='col'><BioCardTopPic imgUrl='https://loichua.church/assets/images-cus/pastor-sang.jpg' name='Pastor Trần Sang' position='Youth Pastor' />
                    </div>
                </div>
                <div className='row'>
                    <div className='col'></div>
                </div>
            </div>
            <CommonFooter authConfig={authCtx} />

        </>
    )
}

export default MotherDay